import { useColors } from "../shared/colors/Colors";

export const WebDevelopment = () => {
    const colors = useColors();

  const styles = {
    appBar: {
      bgcolor: colors.grey,
      "&:hover": {
        boxShadow: "0 0 20px rgb(173, 216, 230, 0.9)",
      },
    },
  };

  return (
    <svg width="10rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill={colors.navy} className="web-icon">
    <title>07-web development</title>
    <g id="_07-web_development" data-name="07-web development">
        <path d="M45,40H3a3,3,0,0,1-3-3V11A3,3,0,0,1,3,8H6v2H3a1,1,0,0,0-1,1V37a1,1,0,0,0,1,1H45a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H42V8h3a3,3,0,0,1,3,3V37A3,3,0,0,1,45,40Z"/>
        <rect x="15" y="39" width="2" height="4"/>
        <path d="M13,47H11V45a3,3,0,0,1,3-3H24v2H14a1,1,0,0,0-1,1Z"/>
        <rect x="31" y="39" width="2" height="4"/>
        <path d="M37,47H35V45a1,1,0,0,0-1-1H29V42h5a3,3,0,0,1,3,3Z"/>
        <rect x="9" y="46" width="30" height="2"/>
        <path d="M40,36H8a3,3,0,0,1-3-3V3A3,3,0,0,1,8,0H40a3,3,0,0,1,3,3V33A3,3,0,0,1,40,36ZM8,2A1,1,0,0,0,7,3V33a1,1,0,0,0,1,1H40a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1Z"/>
        <rect x="6" y="6" width="36" height="2"/>
        <rect x="9" y="3" width="2" height="2"/>
        <rect x="13" y="3" width="2" height="2"/>
        <rect x="17" y="3" width="2" height="2"/>
        <rect x="1" y="30" width="5" height="2"/>
        <rect x="42" y="30" width="5" height="2"/>
        <path d="M32,36H28a1,1,0,0,1-1-1V20.191a5.992,5.992,0,0,1,.6-10.685,1,1,0,0,1,1.4.916V14h2V10.422a1,1,0,0,1,1.4-.916A5.992,5.992,0,0,1,33,20.191V35A1,1,0,0,1,32,36Zm-3-2h2V19.578a1,1,0,0,1,.6-.916,3.991,3.991,0,0,0,1.4-6.3V15a1,1,0,0,1-1,1H28a1,1,0,0,1-1-1V12.359a3.991,3.991,0,0,0,1.4,6.3,1,1,0,0,1,.6.916Z"/>
        <rect x="15" y="10" width="4" height="6" rx="1" ry="1"/>
        <path d="M19,36H15a1,1,0,0,1-1-1V25a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V35A1,1,0,0,1,19,36Zm-3-2h2V26H16Z"/>
        <rect x="13" y="24" width="8" height="2"/>
        <rect x="16" y="15" width="2" height="10"/>
        <rect x="15" y="27" width="4" height="2"/>
        <rect x="9" y="30" width="2" height="2"/>
        <rect x="37" y="30" width="2" height="2"/>
        <rect x="37" y="10" width="2" height="2"/>
        <rect x="9" y="10" width="2" height="2"/>
    </g>
    </svg>
  );
};