import React from 'react';


import CTAButton from '../../atoms/Button/CTAButton/CTAButton';
import ContactBlock from '../ContactBlock/ContactBlock';

const CTAWithContact: React.FC = () => {
  return (
    <div className="cta-contact">
      <CTAButton text="Get In Touch" href="/contact" />
      <ContactBlock />
    </div>
  );
};

export default CTAWithContact;
