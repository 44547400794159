import React from 'react';

interface CTAButtonProps {
  text: string;
  href: string;
}

const CTAButton: React.FC<CTAButtonProps> = ({ text, href }) => {
  return (
    <a href={href} className="cta-button">
      {text}
    </a>
  );
};

export default CTAButton;
