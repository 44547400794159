export const useColors = () => {
  return {
    black: "#000000",
    white: "#FFFFFF",
    appBackGroundColor: "#040707",
    lightGrey: "#B0B0B0",
    mediumGrey: "#555555",
    hoverGrey: "#777777",
    selectedGrey: "#303841",
    hoverDarkGrey: "#2A2F33",
    shadowColor: "rgba(0, 0, 0, 0.5)",
    background: "#101517",
    textLight: "#E0E0E0",
    textMuted: "#B0B0B0",
    borderColor: "#555555",
    hoverBorderColor: "#777777",
    buttonBackground: "#1E90FF",
    buttonHover: "#1C86EE",
    selectedNavOption: "#2A2F33",
    navHoverBackground: "#303841",
    logoBackground: "#101517",
    menuIconColor: "#E0E0E0",
    toggleSelected: "#303841",
    toggleHover: "#2A2F33",
    navy: "#314EA2",
    darkBlue: "#040707",
    grey: "#FFFFFF",
    denimBlue: "#101517",
  };
};
