import React from 'react';
import logo from "../../shared/pictures/data-spark-logo.png";
import './Logo.css';


const Logo =()=>{
    return <img src={logo} alt="Company Logo" className="company-logo"></img>

}

export default Logo;