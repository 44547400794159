import React from 'react';
import TeamTemplate from '../../templates/TeamTemplate/TeamTemplate';
import './TeamPage.css';

const TeamPage: React.FC = () => (
  <div className="team-page">
    <TeamTemplate />
  </div>
);

export default TeamPage;
