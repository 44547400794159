import React from 'react';

interface ContactLinkProps {
  href: string;
  iconClass: string;
  text: string;
}

const ContactLink: React.FC<ContactLinkProps> = ({ href, iconClass, text }) => {
  return (
    <a href={href} className="contact-link">
      <i className={iconClass}></i>
      {text}
    </a>
  );
};

export default ContactLink;
