import React from 'react';
// import SocialMediaIcon from '../atoms/SocialMediaIcon';
import SocialMediaIcon from '../../atoms/SocialMediaLink/SocialMediaLink';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import FontAwesome brand icons

const SocialMediaIconsGroup: React.FC = () => {
  return (
    <div className="social-media-icons">
      <SocialMediaIcon href="https://facebook.com" icon={faFacebookF} alt="Facebook" />
      <SocialMediaIcon href="https://twitter.com" icon={faTwitter} alt="Twitter" />
      <SocialMediaIcon href="https://linkedin.com" icon={faLinkedinIn} alt="LinkedIn" />
      <SocialMediaIcon href="https://instagram.com" icon={faInstagram} alt="Instagram" /> 
    </div>
  );
};

export default SocialMediaIconsGroup;