import React from 'react';
import TeamMemberCard from '../molecules/TeamMemberCard/TeamMemberCard';

import KgotsoImage from '../../assets/image/Kgotso.jpg';
import PhodzoImage from '../../assets/image/phodzo.jpg';
import RenaeImage from '../../assets/image/Renae.png';
import SetjhabaImage from '../../assets/image/Setjhaba.jpeg';


const bioKgotso: string = 'Kgotso is a talented software engineer currently pursuing a career in data engineering. He completed his studies at the University of the Free State in 2023, earning a BSc in IT with a major in Data Science. A passionate F1 fan, Kgotso’s favorite team is Mercedes, and he admires how data is leveraged to secure victories on the track. In his free time, he enjoys developing games, reading comic and manga books, and applying his data science expertise to analyze stock prices.'
const bioPhodzo: string = `Phodzo is a problem solver at heart, holding a BSc in IT (Computer Science) with a major in Data Science, graduating with cum laude honors from the University of the Free State. He currently works at Entelect on the Anglo American Data Analytics team, where he thrives on tackling challenges and finding innovative solutions. Beyond his professional life, Phodzo enjoys playing the piano, producing music, engaging in a game of chess, and staying fit through regular gym workouts`;
const bioSetjhaba: string = `Setjhaba is a dedicated Software Engineer with 1.5 years of experience in designing, developing, and implementing software solutions in the banking sector. He has a strong background in web development, backend systems and is passionate about continuous learning and staying up-to-date with industry trends to deliver innovative and efficient solutions. His goal is to leverage his skills and experience to contribute to impactful projects and drive technological advancements.`;
const bioRanae: string ='Rinae Mudzielwana is a driven mechanical engineering student with a keen interest in technology and innovation. As a key player in the business and marketing team at Data Spark Innovations, a dynamic tech start-up, Rinae enjoys finding creative ways to monetize ideas and drive the company s growth. His unique combination of technical expertise and entrepreneurial flair positions him as a valuable asset in the evolving tech landscape.';

const teamMembers = [
  {
    name: 'Kgotso William Machabelele',
    title: 'Software Developer',
    description: bioKgotso,
    imgSrc: KgotsoImage,
    imgAlt: 'Kgotso Machabelele',
  },
  {
    name: 'Phodzo Nditsheni',
    title: 'Software Developer',
    description: bioPhodzo,
    imgSrc: PhodzoImage,
    imgAlt: 'Phodzo',
  },
  {
    name: 'Renae Mudzielwana',
    title: 'Business and Marketing',
    description: bioRanae,
    imgSrc: RenaeImage,
    imgAlt: 'Renae Mudzielwana',
  },
  {
    name: 'Setjhaba Stonga',
    title: 'Software Developer',
    description: bioSetjhaba,
    imgSrc: SetjhabaImage,
    imgAlt: 'Setjhaba Stonga',
  },


  // Add more team members here...
];

const TeamList: React.FC = () => (
  <div className="team-list">
    {teamMembers.map((member, index) => (
      <TeamMemberCard key={index} {...member} />
    ))}
  </div>
);

export default TeamList;
