import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import DynamicTitle from "../atoms/DynamicTitle";
import NavBar from "../molecules/NavBar";

import { Contact } from "../Pages/Contact";
import { Overview } from "../Pages/Overview";
import TeamPage from "../Pages/TeamPage/TeamPage";
import FooterSection from "../Organisms/FooterSection/FooterSection";

export const MainLayout = () => {
  return (
    <Router>
      <DynamicTitle />
      <NavBar />
      <Routes>
        <Route path="/overview" element={<Overview />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/services" element={<div>Services Page</div>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Overview />} />
      </Routes>
      <FooterSection />
    </Router>
  );
};
