import React from 'react';

import Logo from '../../atoms/Logo/Logo';
import FooterText from '../../atoms/FooterText/FooterText';
import SocialMediaIconsGroup from '../SocialMediaIconsGroup/SocialMediaIconsGroup';

const AddressBlock: React.FC = () => {
  return (
    <div className="address-block">
      <Logo  />
      <FooterText text="Glover and Alethea Street, Centurion, 0163, South Africa" />
      <SocialMediaIconsGroup /> 
    </div>
  );
};

export default AddressBlock;
