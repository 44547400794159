import React from 'react';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/TeamAboutText/Text';
import './TeamMemberCard.css';

type TeamMemberCardProps = {
  name: string;
  title: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
};

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ name, title, description, imgSrc, imgAlt }) => (
  <div className="team-member-card">
    <Image src={imgSrc} alt={imgAlt} className="team-member-img" />
    <div className="team-member-info">
      <Text content={name} className="team-member-name" />
      <Text content={title} className="team-member-title" />
      <Text content={description} className="team-member-description" />
    </div>
  </div>
);

export default TeamMemberCard;
