import { Grid } from "@mui/material";
import React from "react";
import FooterText from "../../atoms/FooterText/FooterText";
import AddressBlock from "../../molecules/AddressBlock/AddressBlock";
import CTAWithContact from "../../molecules/CTAWithContact/CTAWithContact";

const FooterSection: React.FC = () => {
  return (
    <Grid sx={{ mt: "2px" }}>
      <footer className="footer-section">
        <div className="footer-left">
          <AddressBlock />
        </div>
        <div className="footer-right">
          <CTAWithContact />
        </div>
        <div className="footer-bottom">
          <FooterText text="© 2025 DataSpark. All Rights Reserved" />
        </div>
      </footer>
    </Grid>
  );
};

export default FooterSection;
