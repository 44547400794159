import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn,faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import specific icons

interface SocialMediaIconProps {
  href: string;
  icon: any;  // Change this to accept FontAwesome icon
  alt: string;
}

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ href, icon, alt }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="social-media-icon">
      <FontAwesomeIcon icon={icon} title={alt} size="1x" />
    </a>
  );
};

export default SocialMediaIcon;