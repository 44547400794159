import { Container, Divider, Stack } from "@mui/material";

const OurSteps = () => (
  <Container maxWidth="xl">
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      
    </Stack>
  </Container>
);

export default OurSteps;
