import { useColors } from "../shared/colors/Colors";

export const MobileDevelopment = () => {
    const colors = useColors();

  const styles = {
    appBar: {
      bgcolor: colors.grey,
      "&:hover": {
        boxShadow: "0 0 20px rgb(173, 216, 230, 0.9)",
      },
    },
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill={colors.navy} className="web-icon" width="10rem">
    <title>04-app development</title>
    <g id="_04-app_development" data-name="04-app development">
        <path d="M25,48H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H25a3,3,0,0,1,3,3V9H26V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3V45a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V29h2V45A3,3,0,0,1,25,48Z"/>
        <rect x="1" y="8" width="25" height="2"/>
        <rect x="1" y="38" width="26" height="2"/>
        <rect x="11" y="4" width="6" height="2"/>
        <rect x="11" y="42" width="6" height="2"/>
        <rect x="4" y="12" width="2" height="2"/>
        <rect x="8" y="12" width="2" height="2"/>
        <rect x="4" y="16" width="2" height="2"/>
        <path d="M35,33H33a3,3,0,0,1-2.983-2.688,11.975,11.975,0,0,1-1.2-.494,3.068,3.068,0,0,1-4.007-.212l-1.414-1.414a3,3,0,0,1-.211-4.008,12.049,12.049,0,0,1-.5-1.2A3,3,0,0,1,20,20V18a3,3,0,0,1,2.687-2.984,12.049,12.049,0,0,1,.5-1.2,3,3,0,0,1,.211-4.008l1.414-1.414a3.068,3.068,0,0,1,4.007-.212,11.975,11.975,0,0,1,1.2-.494A3,3,0,0,1,33,5h2a3,3,0,0,1,2.983,2.688,11.975,11.975,0,0,1,1.2.494,3.072,3.072,0,0,1,4.007.212l1.414,1.414a3,3,0,0,1,.211,4.008,11.809,11.809,0,0,1,.495,1.2A3,3,0,0,1,48,18v2a3,3,0,0,1-2.688,2.984,11.809,11.809,0,0,1-.495,1.2,3,3,0,0,1-.211,4.008l-1.414,1.414a3.067,3.067,0,0,1-4.007.212,11.975,11.975,0,0,1-1.2.494A3,3,0,0,1,35,33Zm-6.359-5.4a1,1,0,0,1,.488.127,9.973,9.973,0,0,0,2.143.884,1,1,0,0,1,.728.962V30a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-.425a1,1,0,0,1,.728-.962,9.973,9.973,0,0,0,2.143-.884,1,1,0,0,1,1.194.166l.3.3a1.023,1.023,0,0,0,1.415,0l1.414-1.414a1,1,0,0,0,0-1.414l-.3-.3a1,1,0,0,1-.167-1.2,9.939,9.939,0,0,0,.884-2.144A1,1,0,0,1,44.575,21H45a1,1,0,0,0,1-1V18a1,1,0,0,0-1-1h-.425a1,1,0,0,1-.962-.727,9.939,9.939,0,0,0-.884-2.144,1,1,0,0,1,.167-1.2l.3-.3a1,1,0,0,0,0-1.414L41.778,9.808a1.023,1.023,0,0,0-1.413,0l-.3.3a1,1,0,0,1-1.194.166,9.973,9.973,0,0,0-2.143-.884A1,1,0,0,1,36,8.425V8a1,1,0,0,0-1-1H33a1,1,0,0,0-1,1v.425a1,1,0,0,1-.728.962,9.973,9.973,0,0,0-2.143.884,1,1,0,0,1-1.194-.166l-.3-.3a1.025,1.025,0,0,0-1.415,0l-1.414,1.414a1,1,0,0,0,0,1.414l.3.3a1,1,0,0,1,.167,1.2,9.939,9.939,0,0,0-.884,2.144,1,1,0,0,1-.962.727H23a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h.425a1,1,0,0,1,.962.727,9.939,9.939,0,0,0,.884,2.144,1,1,0,0,1-.167,1.2l-.3.3a1,1,0,0,0,0,1.414l1.414,1.414a1.023,1.023,0,0,0,1.413,0l.3-.3A.993.993,0,0,1,28.641,27.6Z"/>
        <path d="M34,26a7,7,0,1,1,7-7A7.008,7.008,0,0,1,34,26Zm0-12a5,5,0,1,0,5,5A5.006,5.006,0,0,0,34,14Z"/>
        <path d="M4.631,39.63,3.217,38.216l6.442-6.442a1,1,0,0,1,1.138-.2,5.029,5.029,0,0,0,7.15-5.265L15.1,29.166a1,1,0,0,1-1.414,0l-2.848-2.848a1,1,0,0,1,0-1.414l2.852-2.852A5.03,5.03,0,0,0,8.421,29.2a1,1,0,0,1-.195,1.138L1.783,36.784.369,35.37,6.34,29.4a7.03,7.03,0,0,1,9.654-8.712,1,1,0,0,1,.277,1.609l-3.316,3.315,1.434,1.433L17.7,23.729a1,1,0,0,1,.876-.278.994.994,0,0,1,.733.555A7.03,7.03,0,0,1,10.6,33.66Z"/>
        <rect x="42" y="33" width="2" height="2"/>
        <rect x="42" y="37" width="2" height="6"/>
        <rect x="38" y="35" width="2" height="2"/>
        <rect x="38" y="39" width="2" height="2"/>
        <rect x="34" y="35" width="2" height="2"/>
    </g>
    </svg>
  );
};