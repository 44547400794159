import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DynamicTitle: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const pathToTitleMap: Record<string, string> = {
      "/": "DataSpark Innovations",
      "/contact": "Contact Us - DataSpark Innovations",
      "/team": "Team - DataSpark Innovations",
    };

    const path = location.pathname;
    const pageTitle = pathToTitleMap[path] || "DataSpark Innovations";
    document.title = pageTitle;
  }, [location]);

  return null;
};

export default DynamicTitle;
