import React from "react";
import "./TeamTemplate.css";
import TeamList from "../../Organisms/TeamList";

const TeamTemplate: React.FC = () => (
  <div className="team-template">
    <h1>Team</h1>
    <h1 className="team-title">Where Data Ignites Innovation</h1>
    <p className="team-paragrap">
      Data Spark Innovations is a dynamic software development company dedicated
      to creating data-driven solutions that transform the way businesses
      operate. We specialize in developing web applications and mobile apps that
      harness the power of data to deliver superior user experiences and drive
      informed decision-making. Data Spark Innovations is a dynamic software
      development company dedicated to creating data-driven solutions that
      transform the way businesses operate. We specialize in developing web
      applications and mobile apps that harness the power of data to deliver
      superior user experiences and drive informed decision-making.
    </p>
    <TeamList />
  </div>
);

export default TeamTemplate;
