import React from 'react';

interface FooterTextProps {
  text: string;
}

const FooterText: React.FC<FooterTextProps> = ({ text }) => {
  return <p className="footer-text">{text}</p>;
};

export default FooterText;
