import React from 'react';
import ContactLink from '../../atoms/ContactLink/ContactLink';

const ContactBlock: React.FC = () => {
  return (
    <div className="contact-block">
      <ContactLink href="mailto:admin@dataspark.co.za" iconClass="email-icon" text="admin@dataspark.co.za" />
      <ContactLink href="tel:+27659458671" iconClass="phone-icon" text="(+27) 65 945 8671" />
    </div>
  );
};

export default ContactBlock;
