// src/components/NavBar.tsx
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavButton } from "../atoms/NavButton";
import { useColors } from "../shared/colors/Colors";
import logo from "../shared/pictures/data-spark-logo.png";

const NavBar: React.FC = () => {
  const colors = useColors();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const styles = {
    appBar: {
      bgcolor: colors.darkBlue,
      border: `1px solid ${colors.hoverGrey}`,
      borderRadius: "20px",
      "&:hover": {
        boxShadow: `0 0 20px ${colors.hoverGrey}`,
      },
    },
    logo: {
      height: isMobile ? "50px" : "60px",
      padding: isMobile ? "20px 5px" : "20px",
    },
    whiteButton: {
      bgcolor: "white",
      color: "black",
      borderRadius: "5px",
      "&:hover": {
        boxShadow: "0 0 20px rgba(173, 216, 230, 0.2)",
        bgcolor: "white",
      },
      textTransform: "none",
    },
  };

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      // Type guard to check if event is KeyboardEvent
      if (
        event.type === "keydown" &&
        "key" in event &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const list = () => (
    <Box
      sx={{
        width: 250,
        bgcolor: colors.appBackGroundColor,
        color: colors.white,
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navOptions.map((option, index) => (
          <ListItem
            button
            component={Link}
            to={option.to}
            key={index}
            sx={{
              bgcolor:
                location.pathname === option.to
                  ? colors.selectedGrey
                  : "inherit",
              "&:hover": { bgcolor: colors.hoverDarkGrey },
              color: colors.white,
            }}
          >
            <ListItemIcon sx={{ color: colors.white }}>
              {option.icon}
            </ListItemIcon>
            <ListItemText primary={option.text} />
          </ListItem>
        ))}
        <ListItem>
          <Button
            component={Link}
            to={"/contact"}
            variant="contained"
            sx={styles.whiteButton}
          >
            Get in Touch
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  const navOptions = [
    { text: "Overview", to: "/overview", icon: <HomeIcon /> },
    { text: "Team", to: "/team", icon: <PeopleIcon /> },
    { text: "Services", to: "/services", icon: <WorkIcon /> },
  ];

  return (
    <Box sx={{ m: 2 }}>
      <AppBar position="static" sx={styles.appBar}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Link to="/overview">
                    <img src={logo} alt="logo" style={styles.logo} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {isMobile ? (
                <div>
                  <IconButton
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    color="inherit"
                  >
                    <MenuIcon sx={{ height: "50px", width: "50px" }} />
                  </IconButton>
                  <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                  >
                    {list()}
                  </Drawer>
                </div>
              ) : (
                <Grid container alignItems="center" spacing={2}>
                  {navOptions.map((option, index) => (
                    <Grid item key={index}>
                      <NavButton text={option.text} to={option.to} />
                    </Grid>
                  ))}
                  <Grid item>
                    <Button
                      component={Link}
                      to="/contact"
                      variant="contained"
                      sx={styles.whiteButton}
                    >
                      Get In Touch
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
